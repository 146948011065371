<template>
  <div class="mb-3 card">

    <div>
      <v-tabs v-model="tab">
        <v-tab key="list">
          {{ $t('erp.lang_freeTextTabs') }}
        </v-tab>
        <v-tab key="add">{{ $t('erp.lang_addFreeTextTag') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <!-- Table -->
            <Datatable v-show="showTable" id="freeTexTagTable" ref="freeTextDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.FREETEXTTAG"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="FreeTextTags"
                       @editEntry="entryEdit"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       show-delete-buttons
                       show-edit-buttons
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('freeTextTags')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('freeTextTags')"
            />
            <v-container v-if="showUpdate" fluid>
              <v-layout>
                <v-flex md12>

                  <!-- Table: Click => edit freetext-->
                  <div>
                    <v-row>
                      <v-col sm="6" cols="12">
                        <v-text-field outlined
                                      dense
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="text"
                                      :label="$t('erp.lang_freeTextTabs')"
                                      autocomplete="off"
                                      required
                        ></v-text-field>
                      </v-col>

                      <v-col sm="6" cols="12">
                        <v-select outlined
                                  dense
                                  v-model="selectedItemgroups"
                                  :items="itemgroups"
                                  attach
                                  chips
                                  deletable-chips
                                  item-text="name"
                                  item-value="id"
                                  :label="$i18n.t('erp.lang_nav_waregroups')"
                                  multiple
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-text-field dense outlined :label="this.$t('generic.lang_position')" type="number"
                                      v-model="position" :min="1"/>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-spacer/>
                        <v-btn text color="error" @click="resetData">{{
                            $t('generic.lang_prev')
                          }}
                        </v-btn>
                        <v-btn color="error" @click="deleteData">{{
                            $t('generic.lang_delete')
                          }}
                        </v-btn>
                        <v-btn color="success" :disabled="text.length < 1" @click="updateData">
                          {{ $t('generic.lang_edit') }}
                        </v-btn>
                      </v-col>
                    </v-row>
<br><br><br>

                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field outlined
                                dense
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="freeTextTagAdd"
                                :label="$t('erp.lang_freeTextTabs')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select outlined
                            dense
                            v-model="selectedItemgroups"
                            :items="itemgroups"
                            chips
                            deletable-chips
                            item-text="name"
                            item-value="id"
                            :label="$i18n.t('erp.lang_nav_waregroups')"
                            multiple
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense outlined :label="this.$t('generic.lang_position')" type="number"
                                v-model="position" :min="1"/>
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-spacer/>
                  <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn color="success" :disabled="freeTextTagAdd.length < 1" @click="addData">{{
                      $t('generic.lang_add')
                    }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </div>
</template>

<style scoped>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import Datatable from "../../datatable/Datatable";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  components: {
    Datatable
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      id: null,
      text: "",
      freeTextTagAdd: "",
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      position: 1,
      selectedItemgroups: [],
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {
          text: this.$t('erp.lang_freeTextTabs'),
          value: "name", sort: "asc"
        },
        {
          text: this.$t('generic.lang_position'),
          value: "position", sort: "asc",
          align: "center"
        }
      ],
      excelColumns: [

        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('erp.lang_freeTextTabs'),
          field: "name",
        },
        {
          label: this.$t('generic.lang_position'),
          field: "position", sort: "asc"
        }
      ],
      itemgroups: []
    }
  },

  async mounted() {
    this.itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");
  },

  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  methods: {
    addData: function () {
      let self = this;

      this.axios.post(ENDPOINTS.ERP.FREETEXTTAG.CREATE, {
        addFreeTextTagName: this.freeTextTagAdd,
        itemgroups: this.selectedItemgroups,
        position: this.position
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_freeTextTag_added'),
            color: "success"
          });

          self.freeTextTagAdd = "";
          self.selectedItemgroups = [];

          self.showList();

          self.$refs.freeTextDatatable.getDataFromApi();
          self.$refs.freeTextDatatable.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.FREETEXTTAG.UPDATE, {
        freeTextTagUUID: this.id,
        freeTextTagName: this.text,
        itemgroups: this.selectedItemgroups,
        position: this.position
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });

          self.$refs.freeTextDatatable.getDataFromApi();
          self.$refs.freeTextDatatable.resetSelectedRows();

          self.resetData();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteFretextTitle'),
        text: this.$t('erp.lang_deleteFretextText'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios.post(ENDPOINTS.ERP.FREETEXTTAG.DELETE, {
            freeTextTagUUID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_freeTextTagDeleted'),
                color: "success"
              });

              self.resetData();
              self.$refs.freeTextDatatable.getDataFromApi();
              self.$refs.freeTextDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    entryEdit(entry) {
      let self = this;

      self.axios.post(ENDPOINTS.ERP.FREETEXTTAG.GET, {
        freeTextTagUUID: entry.id
      }).then((res) => {
        if (res.status === 200) {
          self.id = entry.id;
          self.text = res.data.freeTextTag.textTag;
          self.selectedItemgroups = res.data.freeTextTag.itemgroups;
          self.showTable = false;
          self.showUpdate = true;
          this.position = parseInt(res.data.freeTextTag.position);
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    entryDelete(entry) {
      this.id = entry.id;

      this.deleteData();
    },
    entriesDelete(entries) {
      this.deleteData(entries);
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
      this.position = 1;
      this.selectedItemgroups = [];
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
  },
}
</script>
